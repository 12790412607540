import React from 'react';
import styled, { css } from 'styled-components';

const StyledH1 = styled.h1`
  /*font-size: ${({ theme }) => theme.font.size16};*/
  font-size: 16px;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      /*font-size: ${({
        theme: {
          font: { size36 },
        },
      }) => size36};*/
      font-size: 36px;
    }
  `}
`;

const Logo = () => <StyledH1>Afiszuj się!</StyledH1>;

Logo.propTypes = {};

export default Logo;
