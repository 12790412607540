import React from 'react';
import styled from 'styled-components';

import Icon from 'components/Icon';
import Link from 'components/Link';
// import logoMkdinSvg from 'assets/icons/logo-mkdin.svg';
// import logoNckSvg from 'assets/icons/logo-nck.svg';
// import nckHorizontalSvg from 'assets/icons/nck-horizontal.svg';
import logoAfiszujSvg from 'assets/icons/logo-afiszuj.svg';
import logoAspSvg from 'assets/icons/logo-asp.svg';
import logoIpbSvg from 'assets/icons/logo-ipb.svg';
import logoStguSvg from 'assets/icons/logo-stgu.svg';
import logoGrafmagSvg from 'assets/icons/logo-grafmag.svg';
import logoNotesSvg from 'assets/icons/logo-notes.svg';
import logoColegiumSvg from 'assets/icons/logo-colegium.svg';

import TopRowPartners from './TopRowPartners';
import { IconWrapper } from './IconWrapper';

const logos = [
  // { heightPercentage: 100, svg: logoMkdinSvg },
  // { heightPercentage: 100, svg: logoNckSvg },
  // { heightPercentage: 100, svg: nckHorizontalSvg, oneLine: true },
  { heightPercentage: 88, svg: logoAfiszujSvg, internalLinkUrl: true, linkUrl: '/' },
  { heightPercentage: 100, svg: logoAspSvg, linkUrl: 'https://asp.waw.pl' },
  { heightPercentage: 91, svg: logoIpbSvg, linkUrl: 'https://warsawposterbiennale.com/pl/' },
  { heightPercentage: 50, svg: logoStguSvg, linkUrl: 'https://www.stgu.pl' },
  { heightPercentage: 50, svg: logoGrafmagSvg, linkUrl: 'https://grafmag.pl' },
  { heightPercentage: 70, svg: logoNotesSvg, linkUrl: 'https://notesna6tygodni.pl' },
  { heightPercentage: 100, svg: logoColegiumSvg, linkUrl: '' },
];

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const oneHundretPercentHeight = 60;

const Partners = () => (
  <StyledWrapper>
    <TopRowPartners oneHundretPercentHeight={oneHundretPercentHeight} />

    {logos.map(({ heightPercentage, svg, internalLinkUrl, linkUrl }, index) => {
      const props = {};

      if (internalLinkUrl) {
        props.as = Link;
        props.to = linkUrl;
      } else if (linkUrl) {
        props.as = 'a';
        props.href = linkUrl;
        props.target = '_blank';
        props.rel = 'noreferrer';
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <IconWrapper key={index} {...props}>
          <Icon height={(heightPercentage * oneHundretPercentHeight) / 100} autoWidth svg={svg} />
        </IconWrapper>
      );
    })}
  </StyledWrapper>
);

Partners.propTypes = {};

export default Partners;
