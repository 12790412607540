export const peopleInfo = [
  {
    what: 'autorki projektu',
    manyWho: [
      { link: 'mailto:sonia@jaszczynska.pl', name: 'Sonia Jaszczyńska' },
      { link: 'https://www.behance.net/aniawielunska', name: 'Ania Wieluńska' },
    ],
  },
  {
    what: 'autorzy opracowań tekstowych',
    manyWho: [
      {
        link: 'mailto:al.lewandowska94@gmail.com',
        name: 'Aleksandra Maria Lewandowska-Ferenc',
      },
      { name: 'Andrzej Tomaszewski' },
      { name: 'Michał Warda' },
    ],
  },
  {
    what: 'korekta i redakcja',
    who: 'Elżbieta Stachyra',
    link: 'mailto:elzbieta.stachyra@gmail.com',
  },
  {
    what: 'autorzy fontów',
    manyWho: [
      { link: 'mailto:borys@kosmynka.com', name: 'Borys Kosmynka' },
      { link: 'http://syfonstudio.com', name: 'Filip Tofil' },
      { link: 'https://www.behance.net/aniawielunska', name: 'Ania Wieluńska' },
    ],
  },
  { what: 'ilustracje', who: 'Igor Kubik', link: 'http://www.igorkubik.com' },
  { what: 'kwerenda badawcza', who: 'Agnieszka Woźniak-Wieczorek' },
  {
    what: 'programowanie strony',
    who: 'Michał Czarnota',
    link: 'https://www.linkedin.com/in/micha%C5%82-czarnota',
  },
  { what: 'tłumaczenia', who: 'Anna Naumova', link: 'https://www.behance.net/naumova' },
  { what: 'wsparcie projektowe', who: 'OKI OKI Studio, Mateusz Machalski' },
];
