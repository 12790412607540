import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton, StyledBox, StyledInner } from './styled';

const Hamburger = ({ isOpen, onClick }) => (
  <StyledButton aria-label="Menu główne" aria-expanded={isOpen} onClick={onClick}>
    <StyledBox aria-hidden="true">
      <StyledInner isOpen={isOpen} />
    </StyledBox>
  </StyledButton>
);

Hamburger.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default Hamburger;
