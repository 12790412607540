import styled, { css } from 'styled-components';

import { paddings } from 'components/Container';
import Link from 'components/Link';
import FontSizeManagerButtons from 'components/FontSizeManager';

export const StyledDesktopWrapper = styled.nav`
  display: none;

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        display: flex;
      }
    `}
`;

export const StyledFontSizeManagerButtons = styled(FontSizeManagerButtons)`
  margin-right: 16px;
`;

export const StyledMobileWrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: opacity 300ms ease-in-out, z-index 300ms ease-in-out;
  z-index: 0;
  opacity: 0;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      z-index: 10;
      opacity: 1;
    `}

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        display: none;
      }
    `}
`;

export const StyledUlMobile = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 300ms ease-in-out;
  transform: scale(0.85);

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: scale(1);
    `}
`;

export const StyledUlDesktop = styled.ul`
  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: ${paddings.padding50};
      width: ${({ theme: { imageSize } }) => imageSize};
    }
  `}
`;

export const StyledLi = styled.li`
  ${Link} {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.font.size36};
    text-transform: none;
    letter-spacing: 1px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
