/* eslint-env browser */

// from https://medium.com/swlh/react-hooks-usecookie-hook-26ac06ff36b0

export const getCookieItem = key =>
  typeof document === 'undefined'
    ? ''
    : document.cookie.split('; ').reduce((total, currentCookie) => {
        const item = currentCookie.split('=');
        const storedKey = item[0];
        const storedValue = item[1];

        return key === storedKey ? decodeURIComponent(storedValue) : total;
      }, '');

export const setCookieItem = (key, value, numberOfDays) => {
  const now = new Date();

  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);

  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
};
