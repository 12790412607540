import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import useTheme from 'hooks/useTheme';
import { GalleryFilterContextProvider } from 'components/Gallery';
import { FontSizeManagerProvider } from 'components/FontSizeManager';

const WrapRootElement = ({ children }) => {
  const theme = useTheme();

  return (
    <FontSizeManagerProvider>
      <ThemeProvider theme={theme}>
        <GalleryFilterContextProvider>{children}</GalleryFilterContextProvider>
      </ThemeProvider>
    </FontSizeManagerProvider>
  );
};

WrapRootElement.propTypes = {
  children: PropTypes.node,
};

export default WrapRootElement;
