import styled, { css } from 'styled-components';

export const iconWrapperMarginBottomCss = css`
  margin-bottom: 40px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;

  ${iconWrapperMarginBottomCss}
`;
