import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { textAndImageContainerColumnGap } from './constants';

export const paddings = {
  padding20: '20px',
  padding35: '35px',
  padding50: '50px',
};

const Container = styled.div`
  width: 100%;
  padding-left: ${paddings.padding20};
  padding-right: ${paddings.padding20};

  ${space}

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      margin: auto;
      padding-left: ${paddings.padding35};

      ${({ noPaddingRight }) =>
        noPaddingRight
          ? css`
              padding-right: 0;
            `
          : css`
              padding-right: ${paddings.padding35};
            `}

      ${space}
    }

    ${theme.mediaQueries.largeDesktop.min} {
      max-width: 1200px;
      padding-left: ${paddings.padding50};

      ${({ noPaddingRight }) =>
        noPaddingRight
          ? css`
              padding-right: 0;
            `
          : css`
              padding-right: ${paddings.padding50};
            `}

      ${space}
    }

    ${theme.mediaQueries.extraLargeDesktop.min} {
      max-width: 1920px;
    }
  `}

  ${({ imagePaddingRight, theme, theme: { imageSize } }) =>
    imagePaddingRight &&
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        padding-right: ${parseInt(imageSize, 10) +
        parseInt(textAndImageContainerColumnGap.smallDesktop, 10)}px;
      }

      ${theme.mediaQueries.largeDesktop.min} {
        padding-right: ${parseInt(imageSize, 10) +
        parseInt(textAndImageContainerColumnGap.largeDesktop, 10)}px;
      }
    `}
`;

Container.propTypes = {
  children: PropTypes.node,
  noPaddingRight: PropTypes.bool,
  imagePaddingRight: PropTypes.bool,
};

Container.defaultProps = {
  noPaddingRight: false,
};

export default Container;
