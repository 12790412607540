import PropTypes from 'prop-types';

const fontFamily = {
  Kolumbia: 'KolumbiaRegular, Times, serif',
  Kapra: 'KapraBlackExp, Arial, sans-serif',
  Renesans: 'RenesansRegular, Arial, sans-serif',
  Makkabi: 'MakkabiRegular, Times, serif',
};

export default fontFamily;

export const fontFamilyShape = PropTypes.shape({
  Kolumbia: PropTypes.string,
  Kapra: PropTypes.string,
  Renesans: PropTypes.string,
  Makkabi: PropTypes.string,
});
