import { camelCaseToKebabCase } from 'utils/string';

export const createPathFromRelativePath = relativePath => {
  const [category, filename] = relativePath.split('/');

  return `/galeria/${camelCaseToKebabCase(category)}/${filename.split('.')[0]}`.toLowerCase();
};

export const checkIfImagePathMatches = (sitePath, imageRelativePath) => {
  return sitePath === createPathFromRelativePath(imageRelativePath);
};

export const initiallyFilterAndSortImages = (images, allSitesPaths) => {
  const numberRegex = /\d+/;

  return images
    .filter(({ node: { childImageSharp } }) => !!childImageSharp)
    .sort((a, b) => {
      const numberA = parseInt(numberRegex.exec(a.node.relativePath)[0], 10);
      const numberB = parseInt(numberRegex.exec(b.node.relativePath)[0], 10);

      return numberA > numberB ? 1 : -1;
    })
    .map(element => ({
      ...element,
      context: allSitesPaths.find(({ path }) =>
        checkIfImagePathMatches(path, element.node.relativePath),
      )?.context,
    }))
    .filter(({ context }) => !!context);
};

export const initiallyFilterAndMapAllSitesPaths = allSitesPaths =>
  allSitesPaths
    .filter(({ node: { isCreatedByStatefulCreatePages } }) => !isCreatedByStatefulCreatePages)
    .map(({ node: { path, context } }) => ({
      path,
      context,
    }));
