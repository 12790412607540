import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  StyledGatsbyImage,
  StyledGatsbyLink,
  StyledInfoItem,
  StyledInfoWrapper,
  StyledInfoWrapperInner,
} from './styled';

const GalleryImageItem = ({ fluid, fixed, path, birthplace, birthdate }) => {
  const [isMobileTouch, setIsMobileTouch] = useState(false);

  const end = () => {
    setIsMobileTouch(false);
    window.removeEventListener('touchend', end);
  };
  const start = () => {
    setIsMobileTouch(true);
    window.addEventListener('touchend', end);
  };

  return (
    <StyledGatsbyLink to={path} $isMobileTouch={isMobileTouch} onTouchStart={start}>
      <StyledInfoWrapper>
        <StyledInfoWrapperInner>
          <StyledInfoItem>{birthplace}</StyledInfoItem>
          <StyledInfoItem>{birthdate}</StyledInfoItem>
        </StyledInfoWrapperInner>
      </StyledInfoWrapper>
      <StyledGatsbyImage fluid={fluid} fixed={fixed} />
    </StyledGatsbyLink>
  );
};

GalleryImageItem.propTypes = {
  fluid: PropTypes.object,
  fixed: PropTypes.object,
  path: PropTypes.string,
  birthplace: PropTypes.string,
  birthdate: PropTypes.string,
};

export default GalleryImageItem;
