import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Container from './Container';
import { textAndImageContainerColumnGap } from './constants';

const StyledContentWrapper = styled(Container)`
  display: grid;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      grid-auto-flow: column;
      grid-template-columns: auto ${({ theme: { imageSize } }) => imageSize};
      column-gap: ${textAndImageContainerColumnGap.smallDesktop};
    }

    ${theme.mediaQueries.largeDesktop.min} {
      column-gap: ${textAndImageContainerColumnGap.largeDesktop};
    }
  `}
`;

const TextAndImageContainer = ({ children, ...props }) => (
  <StyledContentWrapper {...props}>{children}</StyledContentWrapper>
);

TextAndImageContainer.propTypes = { children: PropTypes.node };

export default TextAndImageContainer;
