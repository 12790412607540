import { createGlobalStyle, css } from 'styled-components';

import colors from 'styles/colors';
import KapraBlackExpLight from 'assets/fonts/KapraNeuePro-LightExp.woff';
import KapraBlackExpBold from 'assets/fonts/KapraNeuePro-BoldExp.woff';
import KolumbiaRegular from 'assets/fonts/Kolumbia-Regular.woff';
import RenesansRegular from 'assets/fonts/Renesans-Regular.woff';
import MakkabiRegular from 'assets/fonts/Makkabi-Regular.woff';
import { fontSizeMap } from 'components/FontSizeManager/FontSizeManager';

export const bodyScrollLockClassName = 'overflow-hidden';

const normalizeCss = css`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  ol,
  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  button {
    cursor: pointer;
  }
`;

const fontFamilyCss = css`
  @font-face {
    font-family: KapraBlackExp;
    src: url(${KapraBlackExpLight}) format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: KapraBlackExp;
    src: url(${KapraBlackExpBold}) format('woff');
    font-weight: 500;
  }

  @font-face {
    font-family: KolumbiaRegular;
    src: url(${KolumbiaRegular}) format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: RenesansRegular;
    src: url(${RenesansRegular}) format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: MakkabiRegular;
    src: url(${MakkabiRegular}) format('woff');
    font-weight: 400;
  }
`;

const bodyScrollCss = css`
  body.${bodyScrollLockClassName} {
    overflow: hidden;
  }
`;

const bodyBackgroundColorsCss = Object.entries(colors).map(
  ([colorName, colorValue]) => css`
    body.${colorName} {
      background-color: ${colorValue};
    }
  `,
);

export const paragraphFontSizeCss = css`
  font-size: ${({ theme }) => theme.font.size16};
  line-height: 1.5;

  ${({ theme }) => css`
    ${theme.mediaQueries.tablet.min} {
      font-size: ${({
        theme: {
          font: { size18 },
        },
      }) => size18};
    }
  `}
`;

const fontStyle = css`
  html {
    font-size: ${({ theme }) => theme.font.html};

    &.${fontSizeMap.big.className} {
      font-size: ${({ theme }) => theme.font.htmlBig};
    }

    &.${fontSizeMap.huge.className} {
      font-size: ${({ theme }) => theme.font.htmlHuge};
    }
  }

  body {
    font-size: ${({ theme }) => theme.font.body};
    line-height: 1.4;
    font-family: ${({ theme }) => theme.fontFamily.Kapra};
  }

  button {
    font-family: ${({ theme }) => theme.fontFamily.Kapra};
  }

  a {
    color: ${({ theme }) => theme.colors.black};

    &:visited {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  p {
    ${paragraphFontSizeCss}

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    ${({ theme }) => css`
      ${theme.mediaQueries.tablet.min} {
        &:not(:last-child) {
          margin-bottom: ${theme.font.size16};
        }
      }
    `}
  }

  h1 {
    font-size: ${({ theme }) => theme.font.size36};

    ${({ theme }) => css`
      ${theme.mediaQueries.smallDesktop.min} {
        font-size: ${({
          theme: {
            font: { size75 },
          },
        }) => size75};
      }

      ${theme.mediaQueries.largeDesktop.min} {
        font-size: ${({
          theme: {
            font: { size80 },
          },
        }) => size80};
      }

      ${theme.mediaQueries.extraLargeDesktop.min} {
        font-size: ${({
          theme: {
            font: { size100 },
          },
        }) => size100};
      }
    `}
  }

  h2 {
    font-size: ${({ theme }) => theme.font.size20};
    line-height: 1.5;

    ${({ theme }) => css`
      ${theme.mediaQueries.tablet.min} {
        font-size: ${({
          theme: {
            font: { size50 },
          },
        }) => size50};
      }

      ${theme.mediaQueries.tablet.min} {
        font-size: ${({
          theme: {
            font: { size60 },
          },
        }) => size60};
      }
    `}
  }

  h3 {
    font-size: ${({ theme }) => theme.font.size20};
    line-height: 1.5;

    ${({ theme }) => css`
      ${theme.mediaQueries.tablet.min} {
        font-size: ${({
          theme: {
            font: { size36 },
          },
        }) => size36};
      }
    `}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme }) => theme.fontFamily.Kolumbia};
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    transition: background-color 200ms ease-in-out;
    overflow-x: hidden;
    height: 100%;
    scroll-behavior: smooth;
  }

  html {
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
  }

  ${normalizeCss};

  ${fontFamilyCss};

  ${fontStyle};

  ${bodyScrollCss}
  
  ${bodyBackgroundColorsCss}
`;

export default GlobalStyle;

export const resetButtonCss = css`
  background: none;
  border: none;
`;
