import React from 'react';

export const galleryFilters = [
  {
    name: 'Plakaty',
    value: 'plakaty',
    shortText: (
      <p>
        W&nbsp;międzywojniu zlecenie wykonania plakatu było rzeczą kosztowną. Projekt wykonywał
        grafik, a&nbsp;plakat drukowano w&nbsp;kolorze. Trzeba było również uiścić opłatę od
        umieszczenia reklamy na ulicy. Plakat miał przede wszystkim zwracać na siebie uwagę
        i&nbsp;być zwięzły w&nbsp;treści. Składał się z&nbsp;elementu tekstowego i&nbsp;ilustracji.
        Typografię często projektowali sami autorzy. W&nbsp;naszej galerii znajdziecie plakaty
        reklamowe najsłynniejszych międzywojennych artystów: Tadeusza Gronowskiego (w tym{' '}
        <i>Radion sam pierze</i> z&nbsp;1926 r.), Stefana Norblina, Mieczysława Bermana oraz znanego
        malarza Wojciecha Kossaka. Plakaty z&nbsp;działu pochodzą głównie ze zbiorów Muzeum Plakatu
        w&nbsp;Wilanowie.
      </p>
    ),
  },
  {
    name: 'Afisze',
    value: 'afisze',
    shortText: (
      <p>
        Afisze reklamowały przede wszystkim aktualne wydarzenia – zapraszały na bale, mecze
        piłkarskie, otwarcia lodowisk. Oczywiście zachęcały też do kupna węgla, mydła, alkoholu,
        roweru czy kwiatów. Miały charakter tekstowy, chociaż pojawiały się na nich też proste
        elementy ozdobne oraz ilustracyjne. Do ich składu drukarnia wykonująca zlecenie
        wykorzystywała dostępne czcionki (najczęściej te o&nbsp;dużym stopniu pisma). Droższe afisze
        drukowano na kolorowym papierze (różowym czy zielonym) lub wykorzystując metodę „irys”,
        polegającą na mieszaniu ze sobą farb drukarskich, w&nbsp;celu uzyskania efektownego
        gradientu. Formaty afiszy przypominały formaty plakatów reklamowych. W&nbsp;galerii
        znajdziesz przykłady afiszy, w&nbsp;których wykorzystano pierwowzory udostępnianych przez
        nas krojów Renesans, Kolumbia i&nbsp;Makkabi.
      </p>
    ),
  },
  {
    name: 'Reklamy Prasowe',
    value: 'reklama-prasowa',
    shortText: (
      <p>
        Rozwój poligrafii i&nbsp;prasy sprawił, że najbardziej powszechna stała się reklama
        publikowana w&nbsp;czasopismach. Przyjmowała ona formę całostronicowych, kolorowych
        i&nbsp;ilustrowanych reklam lub ogłoszeń prasowych i&nbsp;anonsów (inseratów).
        W&nbsp;czasopismach ilustrowanych, takich jak „Teatr i&nbsp;Życie Wytworne”, „Światowid” czy
        „Kobieta w&nbsp;Świecie i&nbsp;w Domu” pojawiały się reklamy towarów luksusowych –
        samochodów (Peugeot, Hotchkiss, Fiat, Tatra, Durant), w&nbsp;zestawie z&nbsp;oponami
        przeznaczonymi do limuzyn (tzw. pneumatykami), wina szampańskiego Louis de Bary, zegarków
        Omega lub Tissot, a&nbsp;także eleganckich kosmetyków – pudrów, kremów i&nbsp;perfum. Rodzaj
        reklamowanych produktów dostosowany był do profilu gazety. W&nbsp;czasopismach poświęconych
        fotografii reklamowano aparaty, w&nbsp;magazynach dla pań – towary dla gospodyń. „Bluszcz”
        na przykład reklamował sam siebie jako „doskonały organ reklamowy dla wielu artykułów
        z&nbsp; dziedziny zainteresowań kulturalnej kobiety”. Reklamy kolorowe były w&nbsp;prasie
        rzadkością, bo kosztowały o&nbsp;połowę drożej od reklam jednobarwnych. W&nbsp;czasopiśmie
        „Teatr i&nbsp;Życie Wytworne” tuż za kolorową okładką znajdowało się kilka równie kolorowych
        stron z&nbsp;reklamami. Niekiedy były one drukowane na „wykwintnym” papierze kredowym firmy
        J. Franaszek.
      </p>
    ),
  },
  {
    name: 'Ogłoszenia Drobne',
    value: 'ogloszenia-drobne',
    shortText: (
      <p>
        Mniejsze reklamy prasowe oraz ogłoszenia drobne były lapidarne, oszczędne w&nbsp;formie
        i&nbsp; czarno-białe. Zazwyczaj miały wyłącznie tekstowy charakter. Niekiedy tekst
        występował w&nbsp; połączeniu z&nbsp;prostą i&nbsp;symboliczną ilustracją, ozdobną ramką
        i&nbsp;podkreśleniami. W&nbsp;ramach jednego ogłoszenia często różnicowano typografię.
        Tytuły i&nbsp;ważne słowa składano większym stopniem pisma. Reklamy wkomponowywano
        w&nbsp;tekst lub scalano w&nbsp;bloki ogłoszeń drobnych. Poszczególne ogłoszenia
        w&nbsp;kolumnie oddzielano od siebie za pomocą linii. Cenę ogłoszeń uzgadniano w&nbsp;różny
        sposób. W&nbsp;„Teatrze i&nbsp;Życiu Wytwornym” i&nbsp;„Bluszczu” zależała ona od części
        strony zajętej przez reklamę, w&nbsp;„Światowidzie” od szerokości reklamy liczonej od
        1&nbsp;mm&nbsp;w&nbsp;łamie, w&nbsp;„Kurjerze Informacyjnym” od każdego użytego słowa. Inną
        cenę miały ogłoszenia dla poszukujących pracy (niższą), inną zaś nekrologi (odpowiednio
        wyższą).
      </p>
    ),
  },
  {
    name: 'Druki Ulotne',
    value: 'druki-ulotne',
    shortText: (
      <p>
        W&nbsp;galerii znajdziecie przykłady reklamowych druków ulotnych z&nbsp;międzywojnia. Wśród
        nich przede wszystkim ulotki, które składały się z&nbsp;awersu i&nbsp;rewersu oraz drukowane
        były w&nbsp;jednym kolorze. Informowały one o&nbsp;promocjach, cenach i&nbsp;usługach.
        Czasami dołączane były do czasopism. Oprócz tego zobaczycie kilkustronicowe, składane
        i&nbsp;ilustrowane prospekty reklamowe, a&nbsp;także pocztówki, na których, zamiast
        pamiątkowych zdjęć widokowych, umieszczano reklamy słodyczy i&nbsp;leków. W&nbsp;galerii
        znajdują się też ulotki, w&nbsp;których wykorzystano pierwowzory udostępnianych przez nas
        krojów Renesans i&nbsp;Kolumbia.
      </p>
    ),
  },
];
