import { useEffect } from 'react';

import { bodyScrollLockClassName } from 'components/GlobalStyle';

const useBodyLockScroll = enabled => {
  useEffect(() => {
    if (enabled) {
      window.document.body.classList.add(bodyScrollLockClassName);
    } else {
      window.document.body.classList.remove(bodyScrollLockClassName);
    }
  }, [enabled]);
};

export default useBodyLockScroll;
