import styled, { css } from 'styled-components';

import { resetButtonCss } from 'components/GlobalStyle';

const hamHeight = '2px';
const hamburgerWidth = '30px';
const hamburgerHeight = '24px';

export const StyledButton = styled.button`
  ${resetButtonCss};

  display: flex;
  align-items: center;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  opacity: 0.7;
  z-index: 500;

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        display: none;
      }
    `}
`;

export const StyledBox = styled.div`
  text-align: center;
  font: inherit;
  cursor: pointer;
  text-transform: none;
  color: inherit;
  box-sizing: inherit;
  position: relative;
  display: inline-block;
  width: ${hamburgerWidth};
  height: ${hamburgerHeight};
`;

export const StyledInner = styled.div`
  text-align: center;
  font: inherit;
  cursor: pointer;
  text-transform: none;
  color: inherit;
  box-sizing: inherit;
  top: 50%;
  display: block;
  margin-top: -2px;

  &,
  &:after,
  &:before {
    position: absolute;
    width: ${hamburgerWidth};
    height: ${hamHeight};
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: ${hamHeight};
    background-color: ${({ theme }) => theme.colors.black};
  }

  &:after,
  &:before {
    display: block;
    content: '';
  }

  &:before {
    top: -10px;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: translate3d(-9px, 5px, 0) rotate(-45deg) scaleX(0.5);
      `}
  }

  &:after {
    bottom: -10px;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: translate3d(-9px, -5px, 0) rotate(45deg) scaleX(0.5);
      `}
  }
`;
