import styled, { css } from 'styled-components';

export const StyledWrapper = styled.ul`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;

  li {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  font-size: ${({ theme }) => theme.font.previewDefault};

  ${({ $isCurrent }) =>
    $isCurrent &&
    css`
      font-weight: bold;
    `}

  ${({ $big }) =>
    $big &&
    css`
      font-size: ${({ theme }) => theme.font.previewBig};
    `}

  ${({ $huge }) =>
    $huge &&
    css`
      font-size: ${({ theme }) => theme.font.previewHuge};
    `}
`;
