import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import GatsbyTransitionLink from 'gatsby-plugin-transition-link';

const styledLinkFactory = Component => styled(Component)`
  font-size: ${({ theme }) => theme.font.size16};

  @media (any-hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.dark};
    }
  }

  &:active {
    color: ${({ theme }) => theme.colors.dark};
  }

  ${({ $upperCase }) =>
    $upperCase &&
    css`
      text-transform: uppercase;
    `}

  &.linkActive {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

const linkFactory = (Component, displayName) => {
  const StyledComponentLink = styledLinkFactory(Component);
  StyledComponentLink.displayName = `StyledLink-${displayName}`;

  const ComponentLink = ({ children, upperCase, ...props }) => (
    <StyledComponentLink
      $upperCase={upperCase}
      {...props}
      partiallyActive
      activeClassName="linkActive"
    >
      {children}
    </StyledComponentLink>
  );

  ComponentLink.propTypes = {
    children: PropTypes.node,
    upperCase: PropTypes.bool,
  };

  ComponentLink.displayName = `Link-${displayName}`;

  return ComponentLink;
};

export const TransitionLink = styled(linkFactory(GatsbyTransitionLink, 'GatsbyTransitionLink'))``;

const Link = linkFactory(GatsbyLink, 'GatsbyLink');

export default styled(Link)``;
