import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'components/Footer';

import Header from './Header';
import { StyledWrapper, StyledMain } from './styled';

const Layout = ({ children }) => (
  <StyledWrapper>
    <Header />
    <StyledMain>{children}</StyledMain>
    <Footer>footer</Footer>
  </StyledWrapper>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
