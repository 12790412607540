import PropTypes from 'prop-types';

import colors, { colorsShape } from './colors';
import mediaQueries, { mediaQueriesShape } from './mediaQueries';
import font, { fontShape } from './font';
import fontWeight, { fontWeightShape } from './fontWeight';
import fontFamily, { fontFamilyShape } from './fontFamily';

const theme = {
  colors,
  mediaQueries,
  font,
  fontWeight,
  fontFamily,
  imageSize: undefined,
};

export const themeShape = PropTypes.shape({
  colors: colorsShape,
  mediaQueries: mediaQueriesShape,
  font: fontShape,
  fontWeight: fontWeightShape,
  fontFamily: fontFamilyShape,
  imageSize: PropTypes.string,
});

export default theme;
