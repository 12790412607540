import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      grid-gap: 24px;
      grid-template-columns: repeat(3, 1fr);
    }

    ${theme.mediaQueries.largeDesktop.min} {
      grid-template-columns: repeat(4, 1fr);
    }

    ${theme.mediaQueries.extraLargeDesktop.min} {
      grid-template-columns: repeat(5, 1fr);
    }
  `}
`;
