import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { FontSizeManagerContext, fontSizeMap } from './FontSizeManager';
import { StyledButton, StyledWrapper } from './styled';

const isProperSetting = newSettingLabel => {
  return Object.values(fontSizeMap).some(({ label }) => label === newSettingLabel);
};

const FontSizeManagerButtons = ({ className }) => {
  const { currentFontSizeLabel, updateCookie } = useContext(FontSizeManagerContext);

  const handleClick = event => {
    const newSettingLabel = event.target.getAttribute('data-font-size');

    if (isProperSetting(newSettingLabel)) {
      updateCookie(newSettingLabel);
    }
  };

  const isCurrent = settingLabel => settingLabel === currentFontSizeLabel;

  return (
    <StyledWrapper className={className}>
      <li>
        <StyledButton
          $isCurrent={isCurrent(fontSizeMap.default.label)}
          type="button"
          data-font-size={fontSizeMap.default.label}
          onClick={handleClick}
          aria-label="Zmień wielkość czcionki na zwykłą"
        >
          A
        </StyledButton>
      </li>
      <li>
        <StyledButton
          $isCurrent={isCurrent(fontSizeMap.big.label)}
          $big
          type="button"
          data-font-size={fontSizeMap.big.label}
          onClick={handleClick}
          aria-label="Zmień wielkość czcionki na średnią"
        >
          A<sup>+</sup>
        </StyledButton>
      </li>
      {/* <li> */}
      {/*  <StyledButton */}
      {/*    $isCurrent={isCurrent(fontSizeMap.huge.label)} */}
      {/*    $huge */}
      {/*    type="button" */}
      {/*    data-font-size={fontSizeMap.huge.label} */}
      {/*    onClick={handleClick} */}
      {/*    aria-label="Zmień wielkość czcionki na dużą" */}
      {/*  > */}
      {/*    A<sup>++</sup> */}
      {/*  </StyledButton> */}
      {/* </li> */}
    </StyledWrapper>
  );
};

FontSizeManagerButtons.propTypes = { className: PropTypes.string };

export default FontSizeManagerButtons;
