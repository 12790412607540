import React from 'react';
import PropTypes from 'prop-types';

import GlobalStyle from 'components/GlobalStyle';

const WrapPageElement = ({ children }) => (
  <React.Fragment>
    <GlobalStyle />
    {children}
  </React.Fragment>
);

WrapPageElement.propTypes = {
  children: PropTypes.node,
};

export default WrapPageElement;
