import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import { Link as GatsbyLink } from 'gatsby';

export const StyledInfoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: opacity 200ms ease-in-out, z-index 200ms ease-in-out;
  opacity: 0;
  z-index: 0;
`;

export const StyledInfoItem = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.size24};
`;

export const StyledInfoWrapperInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 300ms ease-in-out;
  transform: scale(0.85);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StyledGatsbyImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
  transition: transform 200ms ease-in-out;
  transform: scale(1);
`;

const hoverCss = css`
  ${StyledGatsbyImage} {
    transform: scale(1.03);
  }

  ${StyledInfoWrapper} {
    opacity: 1;
    z-index: 10;
  }

  ${StyledInfoWrapperInner} {
    transform: scale(1);
  }
`;

export const StyledGatsbyLink = styled(GatsbyLink)`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    padding-bottom: 100%;
  }

  ${({ $isMobileTouch }) => $isMobileTouch && hoverCss}

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        &:hover {
          ${hoverCss}
        }
      }
    `}
`;
