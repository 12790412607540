import PropTypes from 'prop-types';

const fontWeight = {
  regular: 400,
  medium: 500,
};

export const fontWeightShape = PropTypes.shape({
  regular: PropTypes.number,
  medium: PropTypes.number,
});

export default fontWeight;
