import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import { NavigationContext } from 'components/Navigation';
import useBodyLockScroll from 'hooks/useBodyLockScroll';

import { StyledMobileWrapper, StyledLi, StyledUlMobile } from './styled';

const MobileNavigation = ({ links }) => {
  const { isMobileOpen, toggleIsMobileOpen } = useContext(NavigationContext);

  useBodyLockScroll(isMobileOpen);

  return (
    <StyledMobileWrapper $isOpen={isMobileOpen} onClick={toggleIsMobileOpen}>
      <StyledUlMobile $isOpen={isMobileOpen}>
        {links.map(({ linkTo, text }) => (
          <StyledLi key={linkTo + text}>
            <Link upperCase to={linkTo}>
              {text}
            </Link>
          </StyledLi>
        ))}
      </StyledUlMobile>
    </StyledMobileWrapper>
  );
};

MobileNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      linkTo: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

export default MobileNavigation;
