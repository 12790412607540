import { useEffect, useState } from 'react';

import useWindowSize from 'hooks/useWindowSize';
import initialTheme from 'styles/theme';
import { breakpoints } from 'styles/mediaQueries';

const calculateImageSize = windowWidth => {
  let imageSize;

  if (windowWidth >= breakpoints.largeDesktop.min) {
    imageSize = '400px';
  } else if (windowWidth >= breakpoints.smallDesktop.min) {
    imageSize = '350px';
  } else {
    imageSize = '100%';
  }

  return imageSize;
};

const useTheme = () => {
  const { windowWidth } = useWindowSize();

  const [theme, setTheme] = useState({
    ...initialTheme,
    windowWidth,
    imageSize: calculateImageSize(windowWidth),
  });

  useEffect(() => {
    if (windowWidth !== theme.windowWidth) {
      setTheme({
        ...theme,
        windowWidth,
        imageSize: calculateImageSize(windowWidth),
      });
    }
  }, [windowWidth]);

  return theme;
};

export default useTheme;
