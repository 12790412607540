import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';

import { StyledDesktopWrapper, StyledFontSizeManagerButtons, StyledUlDesktop } from './styled';

const DesktopNavigation = ({ links }) => {
  return (
    <StyledDesktopWrapper>
      <StyledFontSizeManagerButtons />

      <StyledUlDesktop>
        {links.map(({ linkTo, text }) => (
          <li key={linkTo + text}>
            <Link upperCase to={linkTo}>
              {text}
            </Link>
          </li>
        ))}
      </StyledUlDesktop>
    </StyledDesktopWrapper>
  );
};

DesktopNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      linkTo: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

export default DesktopNavigation;
