// from https://medium.com/swlh/react-hooks-usecookie-hook-26ac06ff36b0

import { useState } from 'react';

import { getCookieItem, setCookieItem } from 'utils/cookies';

/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
const useCookie = (key, defaultValue) => {
  const getCookie = () => getCookieItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value, numberOfDays) => {
    setCookie(value);
    setCookieItem(key, value, numberOfDays);
  };

  return [cookie, updateCookie];
};

export default useCookie;
