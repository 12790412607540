import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Icon = ({ svg: SVG, className }) => {
  return <SVG className={className} />;
};

Icon.propTypes = {
  svg: PropTypes.func,
  className: PropTypes.string,
};

const StyledIcon = styled(Icon)`
  ${({ size, width, height, autoWidth }) => css`
    width: ${autoWidth ? 'auto' : `${width || size}px`};
    height: ${height || size}px;
  `}
`;

StyledIcon.propTypes = {
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  autoWidth: PropTypes.bool,
};

StyledIcon.defaultProps = {
  size: 24,
};

export default StyledIcon;
