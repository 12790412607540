import React from 'react';
import styled from 'styled-components';

import { peopleInfo } from './constants';

export const StyledWrapper = styled.ul`
  font-size: ${({ theme }) => theme.font.size18};
`;

export const StyledWhat = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const PeopleInfo = () => (
  <StyledWrapper>
    {peopleInfo.map(({ what, who, manyWho, link }, index) => {
      let links;

      if (who) {
        links = link ? (
          <a href={link} target="_blank" rel="noreferrer">
            {who}
          </a>
        ) : (
          <span>{who}</span>
        );
      } else if (manyWho) {
        links = manyWho
          .map(({ link: scopedLink, name }) =>
            scopedLink ? (
              <a key={scopedLink} href={scopedLink} target="_blank" rel="noreferrer">
                {name}
              </a>
            ) : (
              <span key={name}>{name}</span>
            ),
          )
          .reduce((prev, current) => {
            return [prev, ', ', current];
          });
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <StyledWhat>{what}: </StyledWhat> {links}
        </li>
      );
    })}
  </StyledWrapper>
);

PeopleInfo.propTypes = {};

export default PeopleInfo;
