import PropTypes from 'prop-types';

const font = {
  html: '10px',
  previewDefault: '18px',
  htmlBig: '12px',
  previewBig: '21.6px',
  htmlHuge: '14px',
  previewHuge: '25.2px',
  body: '1.6rem',
  size16: '1.6rem',
  size18: '1.8rem',
  size20: '2.0rem',
  size24: '2.4rem',
  size36: '3.6rem',
  size45: '4.5rem',
  size50: '5.0rem',
  size60: '6.0rem',
  size75: '7.5rem',
  size80: '8.0rem',
  size100: '10.00rem',
};

export const fontShape = PropTypes.shape({
  html: PropTypes.string,
  previewDefault: PropTypes.string,
  htmlBig: PropTypes.string,
  previewBig: PropTypes.string,
  htmlHuge: PropTypes.string,
  previewHuge: PropTypes.string,
  body: PropTypes.string,
  size16: PropTypes.string,
  size18: PropTypes.string,
  size20: PropTypes.string,
  size24: PropTypes.string,
  size36: PropTypes.string,
  size45: PropTypes.string,
  size50: PropTypes.string,
  size60: PropTypes.string,
  size75: PropTypes.string,
  size80: PropTypes.string,
  size100: PropTypes.string,
});

export default font;
