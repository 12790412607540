import React from 'react';
import PropTypes from 'prop-types';

import { StyledWrapper } from './styled';

const GalleryGrid = ({ children }) => <StyledWrapper>{children}</StyledWrapper>;

GalleryGrid.propTypes = {
  children: PropTypes.node,
};

export default GalleryGrid;
