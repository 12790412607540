import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: background-color 200ms ease-in-out;
`;

export const StyledMain = styled.main`
  position: relative;
  z-index: 0;
  margin-bottom: auto;
`;
