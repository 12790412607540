import React from 'react';
import styled, { css } from 'styled-components';

import Container, { paddings } from 'components/Container';

import Social from './Social';
import PeopleInfo from './PeopleInfo';
import Partners from './Partners';

const StyledWrapper = styled.footer`
  position: relative;
  z-index: 1;
  margin-top: 50px;
  margin-bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.black};

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      margin-top: 100px;
    }
  `}
`;

export const StyledContainer = styled(Container)`
  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      display: flex;
    }
  `}
`;

export const LogoWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      flex: 0 1 auto;
      padding-right: 40px;
    }
  `}
`;

export const MobileSeparator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black};
  margin: 30px -${paddings.padding20};
  width: auto;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      display: none;
    }
  `}
`;

export const InfoWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      flex: 0 0 ${({ theme: { imageSize } }) => imageSize};
    }
  `}
`;

const Footer = () => (
  <StyledWrapper>
    <StyledContainer pt={36} pb={36}>
      <LogoWrapper>
        <Partners />
      </LogoWrapper>

      <MobileSeparator />

      <InfoWrapper>
        <Social />
        <PeopleInfo />
      </InfoWrapper>
    </StyledContainer>
  </StyledWrapper>
);

Footer.propTypes = {};

export default Footer;
