import { useEffect, useState } from 'react';

import debounce from 'utils/debounce';

export default function useWindowSize() {
  const initialState = {
    windowWidth: typeof window === 'undefined' ? 1024 : window.innerWidth,
    windowHeight: typeof window === 'undefined' ? 1024 : window.innerHeight,
  };

  const [windowSize, setWindowSize] = useState(initialState);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }

    const debounced = debounce(handleResize, 200);

    window.addEventListener('resize', debounced);

    return () => window.removeEventListener('resize', debounced);
  }, []);

  return windowSize;
}
