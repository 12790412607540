import React, { useState } from 'react';

export const GalleryFilterContext = React.createContext({
  activeGalleryFilter: undefined,
  setActiveGalleryFilter: undefined,
});

export const GalleryFilterContextProvider = props => {
  const [activeGalleryFilter, setActiveGalleryFilter] = useState(null);

  return (
    <GalleryFilterContext.Provider
      value={{
        activeGalleryFilter,
        setActiveGalleryFilter,
      }}
      {...props}
    />
  );
};
