import styled, { css } from 'styled-components';

import { resetButtonCss } from 'components/GlobalStyle';

const fontSizeCss = css`
  font-size: ${({
    theme: {
      font: { size18 },
    },
  }) => size18};

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        font-size: ${({
          theme: {
            font: { size24 },
          },
        }) => size24};
      }
    `}
`;

export const StyledTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 24px;
  text-transform: uppercase;
  ${fontSizeCss};
`;

export const StyledButtonsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      justify-content: flex-start;
    }
  `}
`;

export const StyledLi = styled.li`
  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &:not(:first-child) {
        padding-left: 22px;
      }

      &:not(:last-child) {
        padding-right: 22px;
      }
    }
  `};
`;

export const StyledFilterButton = styled.button`
  ${resetButtonCss};

  text-transform: uppercase;
  padding: 0;
  margin-bottom: 8px;

  ${fontSizeCss};

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    `}

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      margin-bottom: 0;
    }
  `}
`;

export const StyledWrapper = styled.div`
  margin-bottom: 20px;
`;
