import React, { useContext } from 'react';

import Container from 'components/Container';

import {
  StyledWrapper,
  StyledFilterButton,
  StyledTitle,
  StyledButtonsWrapper,
  StyledLi,
} from './styled';
import { galleryFilters } from './constants';
import { GalleryFilterContext } from './GalleryFilterContext';

const GalleryFilter = () => {
  const { activeGalleryFilter, setActiveGalleryFilter } = useContext(GalleryFilterContext);

  return (
    <StyledWrapper>
      <Container>
        <StyledTitle>Kategorie:</StyledTitle>
        <StyledButtonsWrapper>
          {galleryFilters.map(({ name, value }) => (
            <StyledLi key={value}>
              <StyledFilterButton
                type="button"
                onClick={() => {
                  setActiveGalleryFilter(activeGalleryFilter === value ? null : value);
                }}
                aria-label={`Filtruj zbiory: ${name}`}
                isActive={activeGalleryFilter === value}
              >
                {name}
              </StyledFilterButton>
            </StyledLi>
          ))}
        </StyledButtonsWrapper>
      </Container>

      {activeGalleryFilter && (
        <Container imagePaddingRight mb={20} mt={20}>
          {galleryFilters.find(({ value }) => value === activeGalleryFilter).shortText}
        </Container>
      )}
    </StyledWrapper>
  );
};

GalleryFilter.propTypes = {};

export default GalleryFilter;
