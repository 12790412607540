import React from 'react';
import styled, { css } from 'styled-components';

import logoMkdinSvg from 'assets/icons/logo-mkdin.svg';
import logoNckSvg from 'assets/icons/logo-nck.svg';
import Icon from 'components/Icon';

import { IconWrapper, iconWrapperMarginBottomCss } from './IconWrapper';

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1 0 100%;
  justify-content: center;

  p {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.font.size20};
    align-items: center;
    text-align: center;
    ${iconWrapperMarginBottomCss};

    ${({ theme }) => css`
      ${theme.mediaQueries.smallDesktop.min} {
        text-align: left;
        font-size: ${({
          theme: {
            font: { size16 },
          },
        }) => size16};
      }
    `}
  }

  > p {
    flex: 1 0 100%;
  }

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      justify-content: flex-start;

      > p {
        display: none;
      }
    }
  `}
`;

export const NCKWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      display: flex;
    }
  `}

  p {
    display: none;
  }

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      p {
        display: flex;
      }
    }
  `}
`;

export const VerticalSeparator = styled.div`
  display: none;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      display: block;
      width: 1px;
      background-color: ${({
        theme: {
          colors: { black },
        },
      }) => black};
      margin-right: 30px;
      ${iconWrapperMarginBottomCss};
    }
  `}
`;

const TopRowPartners = () => (
  <StyledWrapper>
    <IconWrapper>
      <Icon height={60} autoWidth svg={logoMkdinSvg} />
    </IconWrapper>

    <NCKWrapper>
      <IconWrapper>
        <Icon height={80} autoWidth svg={logoNckSvg} />
      </IconWrapper>
      <VerticalSeparator />
      <p>
        Dofinansowano ze środków Narodowego Centrum Kultury
        <br />w ramach programu „Kultura w&nbsp;Sieci”
      </p>
    </NCKWrapper>

    <p>
      Dofinansowano ze środków Narodowego Centrum Kultury
      <br />w ramach programu „Kultura w&nbsp;Sieci”
    </p>
  </StyledWrapper>
);

TopRowPartners.propTypes = {};

export default TopRowPartners;
