// https://noahgilmore.com/blog/easy-gatsby-image-components/

import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { GalleryFilterContext } from 'components/Gallery/GalleryFilter';

import {
  createPathFromRelativePath,
  initiallyFilterAndMapAllSitesPaths,
  initiallyFilterAndSortImages,
} from './utils';
import GalleryImageItem from './GalleryImageItem';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const useGalleryImages = () => {
  const data = useStaticQuery(graphql`
    query {
      allGoogleSheetsDataJson {
        edges {
          node {
            ordinalNumber
            filename
            category
            author
            title
            newspaperDetails
            birthdate
            birthplace
            publisher
            link
            source
            rights
            signature
          }
        }
      }

      allSitePage(
        filter: { context: { category: { regex: "/.+/" }, filename: { regex: "/.+/" } } }
      ) {
        edges {
          node {
            path
            isCreatedByStatefulCreatePages
            context {
              category
              filename
            }
          }
        }
      }

      allImages: allFile(filter: { sourceInstanceName: { eq: "galleryImages" } }) {
        edges {
          node {
            childImageSharp {
              fluid(
                sizes: "(max-width: 991px) 466px, (max-width: 1199px) 360px, (max-width: 1199px) 257px, 344px"
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid
              }
            }
            relativePath
          }
        }
      }
    }
  `);
  const initiallyFilterAndMapAllGoogleJson = (allGoogleJsonEdges, allSitesPaths) =>
    allGoogleJsonEdges
      .map(element => ({
        ...element,
        context: allSitesPaths.find(
          ({ context }) =>
            context.category === element.node.category &&
            context.filename === element.node.filename,
        )?.context,
      }))
      .filter(({ context }) => !!context);

  const allSitesPaths = initiallyFilterAndMapAllSitesPaths(data.allSitePage.edges);
  const images = initiallyFilterAndSortImages(data.allImages.edges, allSitesPaths);
  const googleJson = initiallyFilterAndMapAllGoogleJson(
    data.allGoogleSheetsDataJson.edges,
    allSitesPaths,
  );

  return {
    googleJson,
    images: images.map(
      ({
        node: {
          childImageSharp: { fluid, fixed },
          relativePath,
        },
        context,
      }) => {
        return {
          fluid,
          fixed,
          path: createPathFromRelativePath(relativePath),
          context,
        };
      },
    ),
  };
};

const useGalleryFilteredImages = images => {
  const { activeGalleryFilter } = useContext(GalleryFilterContext);

  if (activeGalleryFilter) {
    return images.filter(({ path }) => path.includes(activeGalleryFilter));
  }

  return images;
};

const GalleryImages = () => {
  const { images, googleJson } = useGalleryImages();

  const filteredImages = useGalleryFilteredImages(images);

  return filteredImages.map(({ fluid, fixed, path, context }) => {
    const {
      node: { birthplace, birthdate },
    } = googleJson.find(singleGoogleJson => singleGoogleJson.context === context);

    return (
      <GalleryImageItem
        key={fluid?.src + fixed?.src}
        fluid={fluid}
        fixed={fixed}
        path={path}
        birthplace={birthplace}
        birthdate={birthdate}
      />
    );
  });
};

GalleryImages.propTypes = {
  filename: PropTypes.string,
};

export default GalleryImages;
