import PropTypes from 'prop-types';

export const breakpoints = {
  phone: {
    max: 767,
  },
  tablet: {
    min: 768,
    max: 991,
  },
  smallDesktop: {
    min: 992,
    max: 1199,
  },
  largeDesktop: {
    min: 1200,
    max: 1919,
  },
  extraLargeDesktop: {
    min: 1920,
  },
};

const createQuery = (size, isMin) =>
  `@media screen and (${isMin ? 'min' : 'max'}-width: ${size}px)`;

const mediaQueries = {
  phone: {
    max: createQuery(breakpoints.phone.max),
  },
  tablet: {
    min: createQuery(breakpoints.tablet.min, true),
    max: createQuery(breakpoints.tablet.max),
  },
  smallDesktop: {
    min: createQuery(breakpoints.smallDesktop.min, true),
    max: createQuery(breakpoints.smallDesktop.max),
  },
  largeDesktop: {
    min: createQuery(breakpoints.largeDesktop.min, true),
    max: createQuery(breakpoints.largeDesktop.max, true),
  },
  extraLargeDesktop: {
    min: createQuery(breakpoints.extraLargeDesktop.min, true),
  },
};

export const mediaQueriesShape = PropTypes.shape({
  phone: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  tablet: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  smallDesktop: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  largeDesktop: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  extraLargeDesktop: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
});

export default mediaQueries;
