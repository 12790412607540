import PropTypes from 'prop-types';

const colors = {
  black: '#000000',
  dark: '#444444',
  white: '#ffffff',
  beige: '#f5f5dc',
  lightGreen: '#a6c3b0',
  lightRose: '#ecc5c2',
  lightBlue: '#c9dbea',
};

export const colorsShape = PropTypes.shape({
  black: PropTypes.string,
  dark: PropTypes.string,
  white: PropTypes.string,
  beige: PropTypes.string,
  lightGreen: PropTypes.string,
  lightRose: PropTypes.string,
  lightBlue: PropTypes.string,
});

export default colors;
