import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import useCookie from 'hooks/useCookie';

export const FontSizeManagerContext = React.createContext({
  updateCookie: null,
  currentFontSizeLabel: '',
});

const COOKIE_KEY = 'font-size-setting';

export const fontSizeMap = {
  default: { label: '', className: '' },
  big: { label: 'Big', className: 'fontSizeBig' },
  huge: { label: 'Huge', className: 'fontSizeHuge' },
};

export const FontSizeManagerProvider = ({ children, ...props }) => {
  const [currentFontSizeLabel, updateCookie] = useCookie(COOKIE_KEY, fontSizeMap.default.label);

  const htmlClassName = Object.values(fontSizeMap).find(
    ({ label }) => label === currentFontSizeLabel,
  )?.className;

  const contextValue = {
    updateCookie,
    currentFontSizeLabel,
  };

  return (
    <FontSizeManagerContext.Provider value={contextValue} {...props}>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
        <html className={htmlClassName} />
      </Helmet>
      {children}
    </FontSizeManagerContext.Provider>
  );
};

FontSizeManagerProvider.propTypes = { children: PropTypes.node };

export const { Consumer: FontSizeManagerConsumer } = FontSizeManagerContext;
