/* eslint-disable react/jsx-filename-extension */

import React from 'react';

import { WrapPageElement, WrapRootElement } from 'components/wrapElement';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require,import/no-extraneous-dependencies
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

export const wrapPageElement = ({ element }) => <WrapPageElement>{element}</WrapPageElement>;

export const wrapRootElement = ({ element }) => <WrapRootElement>{element}</WrapRootElement>;

export const shouldUpdateScroll = ({
  routerProps: {
    location: { pathname: currentPathname },
  },
  prevRouterProps: { location: { pathname: prevPathname } = {} } = {},
}) => {
  if (prevPathname && prevPathname !== currentPathname) {
    window.document.body.scrollTo(0, 0);
  }

  return false;
};
