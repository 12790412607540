import React from 'react';
import styled, { css } from 'styled-components';

import facebookSvg from 'assets/icons/facebook.svg';
import Icon from 'components/Icon';
import instagramSvg from 'assets/icons/instagram.svg';
import useEmail from 'hooks/useEmail';

export const SocialIconsWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        margin-bottom: 50px;
      }
    `}
`;

export const StyledIconDesktop = styled(Icon)`
  display: none;

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        margin-right: ${({ size }) => size}px;
        display: block;
      }
    `}
`;

export const StyledIconMobile = styled(Icon)`
  margin-right: ${({ size }) => size}px;

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        display: none;
      }
    `}
`;

export const StyledContactInfo = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.font.size16};

  a {
    display: block;

    @media (any-hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.colors.dark};
      }
    }
  }

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        font-size: ${({
          theme: {
            font: { size18 },
          },
        }) => size18};
      }
    `}
`;

const Social = () => {
  const email = useEmail();

  return (
    <SocialIconsWrapper>
      <a
        title="Link do profilu na Facebooku"
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/afiszuj/"
      >
        <StyledIconDesktop size={50} svg={facebookSvg} />
        <StyledIconMobile size={44} svg={facebookSvg} />
      </a>
      <a
        title="Link do profilu na Instagramie"
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/afiszujsie/"
      >
        <StyledIconDesktop size={50} svg={instagramSvg} />
        <StyledIconMobile size={44} svg={instagramSvg} />
      </a>

      {email && (
        <StyledContactInfo>
          <div>Kontakt:</div>
          <a href={`mailto:${email}`}>{email}</a>
        </StyledContactInfo>
      )}
    </SocialIconsWrapper>
  );
};

Social.propTypes = {};

export default Social;
