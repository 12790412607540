import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const NavigationContext = React.createContext({
  isMobileOpen: undefined,
  setIsMobileOpen: undefined,
});

export const NavigationContextProvider = ({ children }) => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const toggleIsMobileOpen = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <NavigationContext.Provider
      value={{
        isMobileOpen,
        setIsMobileOpen,
        toggleIsMobileOpen,
      }}
    >
      {children({ isMobileOpen, toggleIsMobileOpen })}
    </NavigationContext.Provider>
  );
};

NavigationContextProvider.propTypes = {
  children: PropTypes.func,
};
