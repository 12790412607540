import { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const useEmail = () => {
  const {
    site: {
      siteMetadata: { contactEmail },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            contactEmail
          }
        }
      }
    `,
  );

  const [email, setEmail] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEmail(window.atob(contactEmail));
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return email;
};

export default useEmail;
