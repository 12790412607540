import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

import Link from 'components/Link';
import Container from 'components/Container';
import Logo from 'components/Logo';
import {
  DesktopNavigation,
  MobileNavigation,
  NavigationContextProvider,
} from 'components/Navigation';
import Hamburger from 'components/Hamburger';
import colors from 'styles/colors';
import hexToRgb from 'utils/hexToRgb';

import * as urls from '../../urls';

export const StyledHeaderWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  position: sticky;
  top: 0;
  z-index: 500;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  a {
    text-decoration: none;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${Object.entries(colors).map(([colorName, colorValue]) => {
    const rgb = hexToRgb(colorValue);

    return css`
      @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        body.${colorName} {
          ${StyledHeaderWrapper} {
            background-color: rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9);
          }
        }
      }
    `;
  })}
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      min-height: 60px;
    }
  `}
`;

const links = [
  { linkTo: urls.opracowania, text: 'Opracowania' },
  { linkTo: urls.galeria, text: 'Galeria' },
  { linkTo: urls.doPobrania, text: 'Do pobrania' },
];

const Header = () => (
  <React.Fragment>
    <GlobalStyle />
    <NavigationContextProvider>
      {({ isMobileOpen, toggleIsMobileOpen }) => (
        <React.Fragment>
          <StyledHeaderWrapper isMobileOpen={isMobileOpen}>
            <StyledContainer noPaddingRight>
              <header>
                <Link to={urls.home}>
                  <Logo />
                </Link>
              </header>
              <DesktopNavigation links={links} />
              <Hamburger isOpen={isMobileOpen} onClick={toggleIsMobileOpen} />
            </StyledContainer>
          </StyledHeaderWrapper>
          <MobileNavigation toggleIsMobileOpen={toggleIsMobileOpen} links={links} />
        </React.Fragment>
      )}
    </NavigationContextProvider>
  </React.Fragment>
);

Header.propTypes = {};

export default Header;
